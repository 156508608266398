<template>
  <div class="layout-wrapper">
    <TheHeader />

    <div class="af-container">
      <slot />
    </div>

    <div class="af-footer">
      <TheFooter />
    </div>

    <ExitIntentModal v-if="showExitIntentModal" />

    <portal-target
      name="application-flow-navigator-destination"
      class="navigator"
    ></portal-target>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UI_CONFIGS } from "@/utils/const";

export default {
  name: "AppLayoutLoanApplication",
  components: {
    TheHeader: () => import("@/components/TheHeader"),
    TheFooter: () => import("@/components/TheFooter"),
    ExitIntentModal: () => import("@/components/ExitIntentModal"),
  },
  computed: {
    ...mapGetters({
      autoloadConfigValue: "config/getAutoloadConfigValue",
    }),
    showExitIntentModal() {
      return !!this.$route.meta.mortgage
        ? this.autoloadConfigValue(UI_CONFIGS.EXIT_INTENT_MODAL_MORTGAGE) ===
            "true"
        : this.autoloadConfigValue(UI_CONFIGS.EXIT_INTENT_MODAL) === "true";
    },
  },
};
</script>

<style scoped lang="scss">
.layout-wrapper {
  min-height: 100vh;
  display: flex;
  flex-wrap: nowrap;
  flex-basis: 0;
  flex-direction: column;

  .af-container {
    flex-grow: 1;
    width: 100%;
  }

  .af-footer {
    // display: none;
  }
}

@media only screen and (min-width: 769px) {
  .af-footer {
    width: 100%;
    display: flex !important;
  }
}

.navigator {
  position: sticky;
  bottom: 0;
  z-index: 10;
}
</style>
